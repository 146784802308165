import React from 'react'

// Images 
import detailsImg from '../../assets/images/inner-images/bd1.jpg'
import blogProfile from '../../assets/images/testimonial/tc3.jpg'
import blogProfile2 from '../../assets/images/testimonial/tc2.jpg'
import blogProfile3 from '../../assets/images/testimonial/tc5.jpg'
import BlogSideBar from '../Blog/BlogSideBar'
import { Link } from 'gatsby'

const BogDetails = () => {
    return (
        <section className="details-text-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="blog-details-text-area">
                            <img 
                                className="details-main-img" 
                                src={detailsImg} 
                                alt="Blog Details"
                            />
                            <div className="bdt-text">
                                <div className="blog-date">
                                    <ul>
                                        <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                        <li><i className="far fa-comments"></i> 3 Comments</li>
                                    </ul>
                                </div>
                                <h3 className="details-page-title">How Can Digital Agencies Help Your Business Growth</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printg and typestting industry. Lorem Ipsum has been the industry's the 1500s, printer.</p>
                                <p>Cras mush pardon you knees up he lost his bottle it's all gone to pot faff about porkies arse, barney argy-bargy cracking goal loo cheers spend a penny bugger all mate in my flat, hunky cup of tea I don't want no agro. Off his nut show off show off blow.!</p>
                                <div className="blog-quote">
                                    <p>Ecommerce SEO Agency NOVOS Wins ‘SEO Agency of the Year’ Twice in a Row Pastilla Received Honorable Mention at the First Annual Pantheon Lightning Awards In Marketing We Trust Named Financial Standard Agency of the Year.</p>
                                </div>
                                <p>Cras mush pardon you knees up he lost his bottle it's all gone to pot faff about porkies arse, barney argy-bargy cracking goal loo cheers spend a penny bugger all mate in my flat, hunky dory well get stuffed mate David morish bender lavatory. What a load of rubbish car boot.</p>
                                <h3 className="details-page-title">Handover & Support</h3>
                                <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequ nesciunt. Neque porro quisquam est, it dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                <div className="support-list">
                                    <ul>
                                        <li><i className="fas fa-check"></i> Product Engineering</li>
                                        <li><i className="fas fa-check"></i> Page Load Details (time, size, number of requests)</li>
                                        <li><i className="fas fa-check"></i> Big Data & Analytics</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="blog-text-footer mt-30 pr-20">
                            <div className="tag-area">
                                <ul>
                                    <li><span>Tags:</span> </li>
                                    <li><Link to="/category">Agency,</Link></li>
                                    <li><Link to="/category">Relation,</Link></li>
                                    <li><Link to="/category">Psychology</Link></li>
                                </ul>
                            </div>
                            <div className="social-icons">
                                <ul>
                                    <li><span>Share:</span></li> 
                                    <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f" ></i></a></li>
                                    <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in" ></i></a></li>
                                    <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter" ></i></a></li>
                                    <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog-details-page-form-area">
                            <div className="bd-comments details-text-area pr-20">
                                <h3 className="details-page-title">Comments (3)</h3>
                                <div className="comment-card">
                                    <img 
                                        src={blogProfile} 
                                        alt="Blog Profile"
                                    />
                                    <h5>Ronald Smi</h5>
                                    <span>25 August 2021</span>
                                    <p>The bee's knees bite your arm off bits and bobs he nicked it gosh gutted mate blimey, old off his nut argy bargy vagabond buggered dropped.</p>
                                    <Link to="/blog-details"><i className="fas fa-share"></i> Reply</Link>
                                </div>
                                <div className="comment-card cml-20">
                                    <img 
                                        src={blogProfile2} 
                                        alt="Blog Profile"
                                    />
                                    <h5>David Makel</h5>
                                    <p>The bee's knees bite your arm off bits and bobs he nicked it gosh gutted mate blimey, old off his nut argy bargy vagabond buggered dropped.</p>
                                    <Link to="/blog-details"><i className="fas fa-share"></i> Reply</Link>
                                </div>
                                <div className="comment-card">
                                    <img 
                                        src={blogProfile3} 
                                        alt="Blog Profile"
                                    />
                                    <h5>Jemmy Makel</h5>
                                    <p>The bee's knees bite your arm off bits and bobs he nicked it gosh gutted mate blimey, old off his nut argy bargy vagabond buggered dropped.</p>
                                    <Link to="/blog-details"><i className="fas fa-share"></i> Reply</Link>
                                </div>
                            </div>
                            <div className="bd-form details-text-area pr-20" id="bd-form">
                                <h3 className="details-page-title">Leave A Reply</h3>
                                <p>Your email address will not be published. Required fields are marked*.</p>
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Name*" 
                                                required
                                            /> 
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                placeholder="Email*" 
                                                required
                                            /> 
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Phone*" 
                                                required
                                            /> 
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Website" 
                                                required
                                            /> 
                                        </div>
                                        <div className="col-md-12">
                                            <textarea rows="5" className="form-control" placeholder="Message*" required></textarea> 
                                            <div className="mb-3 form-check">
                                                <input 
                                                    type="checkbox" 
                                                    className="form-check-input" 
                                                    id="exampleCheck1"
                                                />
                                                <label className="form-check-label" htmlFor="exampleCheck1">Save my name, email, and website in this browser for the next time I comment.</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="default-button" type="submit" aria-label="submit"><span>Post A Comment <i className="fas fa-arrow-right"></i></span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="pt-30 pl-20">
                            <BlogSideBar />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BogDetails
